import { forEachHeader } from './helpers/headers'
import { stringify } from './helpers/querystrings'

export
function ajax(url, init, callback) {
  if (typeof init === 'function') {
    callback = init
    init = {}
  }
  callback = safeCallback(callback)

  const { method = 'GET', headers = [], body } = init || {}

  const xhr = new XMLHttpRequest()
  xhr.open(method, url)

  forEachHeader(headers, (value, name) => {
    xhr.setRequestHeader(name, value)
  })

  addCredentials(xhr)

  xhr.onload = () => {
    const receivedJson = (
      xhr.responseType === 'json' ||
      xhr.getResponseHeader('content-type') === 'application/json'
    )

    const createCustomError = (code, message) => {
      const error = new TypeError(message)

      error.code = error.number = code

      return error
    }

    if (receivedJson) {
      try {
        const { code, data, msg: message } = JSON.parse(xhr.responseText)
        if (code !== 200) {
          callback(createCustomError(code, message))
        } else {
          callback(null, data)
        }
      } catch (e) {
        callback(e)
      }

      return
    }

    if (200 <= xhr.status && xhr.status <= 299) {
      if (init?.assumeJson) {
        callback(xhr.responseText)
      } {
        callback(null, xhr.responseText)
      }

      return
    }

    callback(createCustomError(xhr.status, xhr.statusText))
  }

  xhr.onerror = e => callback(e)

  if (method === 'GET' || method === 'HEAD' || !body) {
    xhr.send(null)

    return
  }

  const packaged = (
    typeof FormData !== 'undefined' && body instanceof FormData ||
    typeof URLSearchParams !== 'undefined' && body instanceof URLSearchParams ||
    typeof Blob !== 'undefined' && body instanceof Blob ||
    typeof ArrayBuffer !== 'undefined' && body instanceof ArrayBuffer
  )

  if (packaged) {
    xhr.send(body)

    return
  }

  if (typeof body === 'object') {
    let data, contentType
    try {
      data = JSON.stringify(body)
      contentType = 'application/json'
    } catch (e) {
      data = stringify(body)
      contentType = 'application/x-www-form-urlencoded'
    }

    xhr.setRequestHeader('content-type', contentType)
    xhr.send(data)
  } else {
    // xhr.setRequestHeader('content-type', 'text/plain')
    xhr.send(String(body))
  }
}

function addCredentials(xhr) {
  const token = window.Laravel.csrfToken
  if (token) {
    xhr.setRequestHeader('X-CSRF-TOKEN', token)
  }
}

function safeCallback(callback) {
  let called = false

  return function (error, data) {
    if (called) {
      return
    }
    called = true

    try {
      callback(error, data)
    } catch (e) {

    }
  }
}
