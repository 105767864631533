import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/corefeatures/net/ajax'

export
function PackageCardPurchasedViewProvider(SuperComponent) {
  @Component()
  class PackageCardPurchasedView extends SuperComponent {
    mobileList = []

    mounted() {
      this.loadMobileList()
    }

    loadMobileList() {
      ajax(
        '/api/mobile_list?id=' + this.$route.params.id,
        {
          method: 'GET',
          // body: {
          //   id: this.$route.params.id,
          // },
        },

        (error, data) => {
          if (error) {
            this.$message({ message: error.message, type: 'error' })
          } else {
            this.mobileList = data
          }
        }
      )
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: operationColumnView } = root.query({ as: <el-table-column label="操作" /> }).next()

      if (operationColumnView) {
        const renderScopedSlot = operationColumnView.scopedSlots.default

        operationColumnView.scopedSlots.default = scope => {
          if (scope.row.type === 3) {
            scope.row.gmt_pay = true
          }

          return renderScopedSlot.call(this, scope)
        }
      }

      if (this.dialogFormVisible) {
        const { value: siblingView } = root.query({ as: <el-row />, parentAs: <el-dialog title="添加白名单" /> }).next()

        siblingView?.after(
          <el-row class="row-mobile">
            <el-col span={ 12 }>
              <el-table stripe={ true } data={ this.mobileList }>
                <el-table-column prop="mobile" label="手机号码"></el-table-column>
              </el-table>
            </el-col>
          </el-row>
        )
      }

      return vnode
    }
  }


  return PackageCardPurchasedView
}
