import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/corefeatures/net/ajax'

import './package-card-create.css'

export
function PackageCardCreateViewProvider(SuperComponent) {
  @Component()
  class PackageCardCreateView extends SuperComponent {
    purchaseBtnImage = null

    /** @override */
    cardTypeList = [
      { id: 1, value: '普通卡' },
      { id: 2, value: '专享卡' },
      { id: 3, value: '销售专享卡' },
      { id: 4, value: '手机验证卡' },
    ]

    /** @override */
    // addDiscountPackageCard(data) {
    //   data.title = this.form.title

    //   return super.addDiscountPackageCard(data)
    // }

    handleUpload(data) {
      this.doUploadFile(data.file, path => {
        this.imageUrl = path
      })
    }

    handleUpload2(data) {
      this.doUploadFile(data.file, path => {
        this.imageUrlBg = path
      })
    }

    handleUpload3(data) {
      this.doUploadFile(data.file, path => {
        this.purchaseBtnImage = path
      })
    }

    doUploadFile(file, callback) {
      const formData = new FormData()

      formData.append('image', file)

      this.$http
        .post('/api/upload_image', formData, { emulateJSON: true })
        .then(
          res => {
            if (res.body.code === 200) {
              callback(res.body.data.path)
            } else {
              this.$message.error(res.body.msg)
            }
          },
          res => {
            this.$message.error(res.body.msg ?? res.body.data.msg)
          }
        )
    }

    /** @override */
    handleSubmit() {
      this.changeLoading(true)

      const data = {
        ...this.form,

        price          : Number(this.form.price).toFixed(2),
        pay_amount     : Number(this.form.pay_amount).toFixed(2),

        validity_days  : this.form.validity,

        gmt_start      : this.form.date[0] ?? '',
        gmt_end        : this.form.date[1] ?? '',

        img_path       : this.imageUrl,

        popup_img      : this.imageUrlBg,

        button_img     : this.purchaseBtnImage,
      }

      this.addDiscountPackageCard(data).then(res => {
        this.$router.push({ name: "package_card" })
      })
    }

    /** @override */
    getCouponList() {
      ajax(
        '/api/getActivityCoupon',

        (error, data) => {
          if (error) {
            this.$message({ message: error.message, type: 'error' })
          } else {
            this.coupon = data.data
          }
        }
      )
    }

    /** @override */
    beforeImageUpload(file) {
      if (!/^image\//.test(file.type)) {
        this.$message.error(`上传图片格式非法!`)

        return false
      }

      const MAX_SIZE = 2 * (1 << 20)

      if (file.size > MAX_SIZE) {
        this.$message.error("上传图片大小不能超过 2MB!")

        return false
      }

      return true
    }

    isVipCard(card) {
      const type = Number(card.type)

      return type === 3 || type === 4
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      // root.className = `${root.className} package-card-create-main`
      root.classList.add('package-card-create-main')

      const { value: sibilingView1 } = root.query({ as: <el-form-item label="套餐名称" /> }).next()

      sibilingView1?.after(
        <el-form-item label="套餐别名" rules={ this.rules }>
          <el-input value={ this.form.title } onInput={ e => (this.form.title = e) } placeholder='请填写套餐别名' />
        </el-form-item>
      )

      const { value: sibilingView2 } = root.query({ as: <el-form-item label="套餐售价" /> }).next()

      sibilingView2?.after(
        <el-form-item label="套餐售价" rules={ this.rules }>
          <el-input value={ this.form.pay_amount } onInput={ e => (this.form.pay_amount = e) } placeholder='请填写套餐售价' />
        </el-form-item>
        ,

        <el-form-item label="套餐原价" rules={ this.rules }>
          <el-input value={ this.form.price } onInput={ e => (this.form.price = e) } placeholder='请填写套餐原价' />
        </el-form-item>
      )
      sibilingView2?.remove()

      if (this.isVipCard(this.form)) {
        const { value: sibilingView3 } = root.query({ as: <el-form-item label="套餐卡类型" /> }).next()

        sibilingView3?.after(
          <el-form-item label="绑定设备号" rules={ this.rules }>
            <el-input value={ this.form.device_code } onInput={ e => (this.form.device_code = e) } placeholder='请填写绑定设备号' />
          </el-form-item>
        )
      }

      const { value: sibilingView4 } = root.query({ as: <el-form-item label="卡底图图片" /> }).next()

      sibilingView4?.before(
        <el-form-item label="购买按钮图片" rules={ this.rules }>
          <el-upload
            class="purchase-button-uploader ad-uploader"
            action="/api/upload_image"
            http-request={ this.handleUpload3.bind(this) }
            show-file-list={ false }
            before-upload={ this.beforeImageUpload.bind(this) }
          >
            {
              this.purchaseBtnImage ?
                <img src={ this.purchaseBtnImage } class="purchase_img" /> :
                <i class="el-icon-plus avatar-uploader-icon" />
            }
          </el-upload>
        </el-form-item>
      )

      const { value: sibilingView5 } = root.query({ as: <el-form-item label="弹窗底图图片" /> }).next()

      sibilingView5?.remove()

      const { value: sibilingView6 } = root.query({ as: <el-form-item label="轮播图片" /> }).next()

      sibilingView6?.nextElementSibling?.()?.remove()
      sibilingView6?.remove()

      return vnode
    }
  }


  return PackageCardCreateView
}
