import { equals } from '~/commons/lang/strings'

export
function headersToArray(headers) {
  if (Array.isArray(headers)) {
    return headers
  }

  if (typeof Headers !== 'undefined' && headers instanceof Headers) {
    const result = []
    headers.forEach((value, name) => {
      result.push([ name, value ])
    })
    return result
  }

  if (typeof headers === 'object') {
    const result = []
    for (const name of Object.keys(headers)) {
      result.push([ name, headers[name] ])
    }
    return result
  }

  return []
}

export
function setHeader(headers, name, value) {
  if (Array.isArray(headers)) {
    headers.push([ name, value ])
  } else
  if (typeof Headers !== 'undefined' && headers instanceof Headers) {
    headers.append(name, value)
  } else
  if (typeof headers === 'object') {
    headers[name] = value
  }
}

export
function getHeader(headers, name) {
  if (Array.isArray(headers)) {
    for (const header of headers) {
      if (equals(name, header[0], true)) {
        return header[1]
      }
    }
    return null
  } else
  if (typeof Headers !== 'undefined' && headers instanceof Headers) {
    return headers.get(name)
  } else
  if (typeof headers === 'object') {
    return headers[name] ?? null
  }

  return null
}

export
function forEachHeader(headers, callback) {
  if (Array.isArray(headers)) {
    for (const header of headers) {
      const [ name, value ] = header
      callback(value, name)
    }
  } else
  if (typeof Headers !== 'undefined' && headers instanceof Headers) {
    headers.forEach(callback)
  } else
  if (typeof headers === 'object') {
    for (const name of Object.keys(headers)) {
      callback(headers[name], name)
    }
  }

  return null
}
