import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { ajax } from '~/corefeatures/net/ajax'

import { MainHeader } from '~/widgets/MainHeader'

import './bills.css'

@Component({
  components: {
    'main-header': MainHeader,
  },
})
export
class BillsReportView extends ReactiveComponent {
  agentOptions = []
  supervisorOptions = []

  queryAgent = ''
  querySupervisorList = []
  queryTime = []

  exporting = false

  formData = {}

  mounted() {
    this.loadData()
  }

  loadData() {
    this.loadAgentData()
    this.loadSupervisorData()
  }

  loadAgentData() {
    this.getFilterOptions({ type: 1 }, data => this.agentOptions = data)
  }

  @Watch('queryAgent')
  loadSupervisorData() {
    if (this.queryAgent) {
      this.getFilterOptions({ type: 2, id: this.queryAgent }, data => this.supervisorOptions = data)
    }
  }

  @Watch('queryAgent')
  resetQuerySupervisorList() {
    this.querySupervisorList = []
  }

  exportData() {
    this.exporting = true

    ajax(
      '/api/supervisor_order_month_export',
      {
        method: 'POST',
        body: {
          supervisor_id: this.querySupervisorList,
          b_time: this.queryTime[0],
          e_time: this.queryTime[1],
        },

        assumeJson: true,
      },

      (error, data) => {
        this.exporting = false

        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.$message({ message: '导出成功', type: 'success' })
          this.download(data)
        }
      }
    )
  }

  getFilterOptions(config, callback) {
    const { type, id } = config

    ajax(
      '/api/get_search_condition',
      {
        method: 'POST',
        body: { type, id },
      },
      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          callback(data)
        }
      }
    )
  }

  download(url) {
    const anchor = document.createElement('a')

    anchor.download = ''
    anchor.href = url
    document.body.append(anchor)
    anchor.click()
    anchor.remove()
  }

  render() {
    return (
      <section class="bills-report-page">
        <main-header>
          <span slot="header-title">月对账单</span>
        </main-header>

        <main class="bills-report-main">
          <el-form label-position="left" label-width="120px" model={ this.formData } onInput={ e => (this.formData = e) }>
            <el-form-item label="时间">
              <el-date-picker placeholder="请选择时间区间" type="daterange" value={ this.queryTime } onInput={ time => this.queryTime = time.map(date => date && date.toLocaleDateString('sv-SE') || '') } format="yyyy-MM-dd" style={ { width: '400px' } }/>
            </el-form-item>

            <el-form-item label="经销商">
              <el-select placeholder="请选择代理商" value={ this.queryAgent } onInput={ e => (this.queryAgent = e) } style={ { width: '400px !important', marginBottom: '24px' } }>
                {
                  this.agentOptions.map(agent => (
                    <el-option key={ agent.id } value={ agent.id } label={ agent.name } />
                  ))
                }
              </el-select>

              <el-transfer value={ this.querySupervisorList } onInput={ e => (this.querySupervisorList = e) } data={ this.supervisorOptions } titles={ [ '可选经销商','已选经销商' ] } Props={ { key: 'id', label: 'name' } } scopedSlots={
                {
                  default: scope => (
                    <el-row class="supervisor-list__content">
                      <el-col span={ 24 } title={ scope.row.name }>{ scope.row.name }</el-col>
                    </el-row>
                  )
                }
              } />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" onClick={ () => this.exportData() } loading={ this.exporting }>导出对账单</el-button>
            </el-form-item>
          </el-form>
        </main>
      </section>
    )
  }
}
