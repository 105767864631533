import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/corefeatures/net/ajax'

export
function FreeChargeIndexViewProvider(SuperComponent) {
  @Component()
  class FreeChargeIndexView extends SuperComponent {
    get operationItems() {
      return [
        {
          type: 1,
          allowNetwork: true,
          allowFollow: true,
          allowData: true,
        },
        {
          type: 2,
          allowNetwork: true,
          allowFollow: true,
          allowData: true,
        },
        {
          type: 3,
          allowNetwork: true,
          allowFollow: true,
          allowData: true,
        },
        {
          type: 4,
          allowNetwork: true,
          allowFollow: false,
          allowData: false,
        },

      ]
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: columnView } = root.query({ as: <el-table-column prop="" />}).next()

      if (columnView) {
        columnView.scopedSlots.default = scope => {
          const type = +scope.row.type

          for (const item of this.operationItems) {
            if (type === item.type) {
              return (
                <el-row gutter={ 4 } style="width: 20em; margin: auto;">
                  <el-col span={ 8 }>
                  {
                    item.allowNetwork &&
                    <router-link to={ { name: 'free_charge.network', query: { type } } }>
                      <el-button type="primary">网点配置</el-button>
                    </router-link>
                  }
                  </el-col>

                  <el-col span={ 8 } style="min-height: 1em;">
                  {
                    item.allowFollow &&
                    <router-link to={ { name: 'free_charge.follow', query: { type } } }>
                      <el-button type="primary">赠送</el-button>
                    </router-link>
                  }
                  </el-col>

                  <el-col span={ 8 } style="min-height: 1em;">
                  {
                    item.allowData &&
                    <router-link to={ { name: 'free_charge.data', query: { type } } }>
                      <el-button>数据统计</el-button>
                    </router-link>
                  }
                  </el-col>
                </el-row>
              )
            }
          }

          return null
        }
      }

      return vnode
    }

  }


  return FreeChargeIndexView
}
