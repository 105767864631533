export
const KEY_VALUE_DELIMETER = '='

export
const SEGMENT_DELIMETER = '&'

export
function stringify(value) {
  if (Array.isArray(value)) {
    return stringifyArray(value)
  }

  if (typeof value === 'object') {
    const segments = []
    for (const key of Object.keys(value)) {
      segments.push([ key, value[key] ])
    }
    return stringifyArray(segments)
  }

  return String(value)
}

export
function parse(string) {
  return (
    string
      .split(SEGMENT_DELIMETER)
      .map(segment => {
        const [ name, value = '' ] = segment.split(KEY_VALUE_DELIMETER)
        if (!name) {
          return null
        }

        return [ name, value ].map(decodeURIComponent)
      }).
      filter(Boolean)
  )
}

function stringifyArray(value) {
  return (
    value
      .map(item => {
        const [ name, value = '' ] = item
        if (!name) {
          return null
        }

        return [ name, value ].map(encodeURIComponent).join(KEY_VALUE_DELIMETER)
      })
      .filter(Boolean)
      .join(SEGMENT_DELIMETER)
  )
}
