import { ReactiveComponent } from '~/commons/component/ReactiveComponent'
import { Component, Watch } from '~/commons/component/decorators'

import { lastDateOfMonth, formatDate } from '~/commons/lang/dates'

import { ajax } from '~/corefeatures/net/ajax'

import { MainHeader } from '~/widgets/MainHeader'

@Component({
  components: {
    'main-header': MainHeader,
  },
})
export
class OrderDiffView extends ReactiveComponent {
  querySupervisor = ''
  queryTimeRange = [ '', '' ]

  queryPageIndex = 1
  queryPageSize = 20

  diffSource = null

  supervisorOptions = []

  tableData = []

  loading = false

  uploadedFileList = []

  mounted() {
    this.loadSupervisorOptions()
  }

  @Watch('querySupervisor')
  @Watch('queryTimeRange')
  @Watch('diffSource')
  loadDiffData() {
    if (!this.diffSource) {
      return
    }

    ajax(
      '/api/v1/api_check_order',
      {
        method: 'POST',
        body: {
          supervisor_id: this.querySupervisor,

          b_time: this.queryTimeRange[0] && formatDate(this.queryTimeRange[0]),
          e_time: this.queryTimeRange[1] && formatDate(this.queryTimeRange[1]),
        },

        assumeJson: true,
      },

      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.tableData = data.data
        }
      }
    )
  }

  loadSupervisorOptions() {
    ajax(
      '/api/supervisor_list',
      {
        method: 'GET',
      },
      (error, data) => {
        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
        } else {
          this.supervisorOptions = data
        }
      }
    )
  }

  importData(file) {
    this.loading = true
    this.uploadedFileList = [ { name: file.name } ]

    const form = new FormData()

    form.append('order', file)
    form.append('supervisor_id', this.querySupervisor)
    form.append('b_time', this.queryTimeRange[0] && formatDate(this.queryTimeRange[0]))
    form.append('e_time', this.queryTimeRange[1] && formatDate(this.queryTimeRange[1]))

    ajax(
      '/api/import_data',
      {
        method: 'POST',
        body: form,
      },

      (error, successMessage) => {
        this.loading = false

        if (error) {
          this.$message({ message: error.message || error, type: 'error' })
          this.uploadedFileList = []
        } else {
          if (successMessage) {
            this.$message({ message: successMessage, type: 'success' })
          }

          this.diffSource = file
        }
      }
    )
  }

  unimporData() {
    this.diffSource = null
    this.uploadedFileList = []
  }

  render() {
    return (
      <section class="order-diff-page">
        <main-header>
          <span slot="header-title">订单核对</span>
        </main-header>

        <section style="margin-top: 20px;">
          <el-row gutter={ 24 }>
            <el-col span={ 3 }>
              <el-select placeholder="经销点查询" value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } filterable clearable>
                {
                  this.supervisorOptions.map(item => (
                    <el-option label={ item.name } value={ item.id } />
                  ))
                }
              </el-select>
            </el-col>
            <el-col span={ 6 }>
              <el-date-picker placeholder="业务日期查询" value={ this.queryTimeRange } onInput={ e => (this.queryTimeRange = e) } type="datetimerange" format="yyyy-MM-dd" clearable />
            </el-col>
            <el-col span={ 3 }>
              <el-upload multiple={ false } limit={ 1 } onRemove={ () => this.unimporData() } before-upload={ e => this.importData(e) } file-list={ this.uploadedFileList } action="/api/import_data_fake" { ...{ attrs: { 'on-remove': this.unimporData.bind(this) } }} >
                <el-button slot="trigger" type="primary" loading={ this.loading }>导入数据源对比</el-button>
              </el-upload>
            </el-col>
          </el-row>
        </section>


        <section style="margin-top: 20px;" class="order-diff-main">
          <el-table data={ this.tableData } border style="width: 100%">
            <el-table-column label="订单" scopedSlots={{
              default: scope => (
                <span>{ scope.data_order.order_no }</span>
              )
            }} />
            <el-table-column prop="end_date" label="金额" scopedSlots={{
              default: scope => {
                if (scope.data_order.order_amount !== scope.api_order.order_amount) {
                  return (
                    <span>
                      <font color="red">- { scope.data_order.order_amount }</font>
                      <br />
                      <font color="green">+ { scope.api_order.order_amount }</font>
                    </span>
                  )
                }

                return scope.data_order.order_amount
              }
            }} />
            <el-table-column prop="status" label="状态" scopedSlots={{
              default: scope => (
                <span>
                  <font color="red">- { scope.data_order.status }</font>
                  <br />
                  <font color="green">+ { scope.api_order.status }</font>
                </span>
              )
            }} />
            <el-table-column label="订单来源" scopedSlots={{
              default: scope => (
                <span>{ scope.data_order.company }</span>
              )
            }} />
          </el-table>

          <div class="pagination block" style="margin-top: 10px;">
            <el-pagination currentPage={ this.queryPageIndex } pageSize={ this.queryPageSize } layout="total, prev, pager, next, sizes, jumper" onCurrentChange={ e => (this.queryPageIndex = e) } onSizeChange={ e => (this.queryPageSize = e) } on-current-change={ e => (this.queryPageIndex = e) } on-size-change={ e => (this.queryPageSize = e) } />
          </div>
        </section>
      </section>
    )
  }
}
