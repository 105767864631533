import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/corefeatures/net/ajax'

export
function PackageCardIndexViewProvider(SuperComponent) {
  @Component()
  class PackageCardIndexView extends SuperComponent {
    cardTypes = {
      1: '普通卡',
      2: '专享卡',
      3: '销售专享卡',
      4: '手机验证卡',
    }

    getList() {
      this.discountPackageCardList(this.search).then(res => {
        this.tableList = res.list
        this.supervisor = res.supervisor
        this.vipUrl = `${res.vip_url}?id=`
        // 增加复制链接
        this.tableList.data.map(item => {
          item.vipUrl = this.vipUrl + item.id + '&type=' + item.type
          item.actualType = item.type
        })
      })
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: operationColumnView } = root.query({ as: <el-table-column label="操作" /> }).next()

      if (operationColumnView) {
        const renderScopedSlot = operationColumnView.scopedSlots.default

        operationColumnView.scopedSlots.default = scope => {
          let resetScopeData

          if (scope.row.type === 3 || scope.row.type === 4) {
            const originalType = scope.row.type

            scope.row.type = 2

            resetScopeData = () => {
              scope.row.type = originalType
            }
          }

          const slot = renderScopedSlot.call(this, scope)

          resetScopeData?.()

          return slot
        }
      }

      operationColumnView?.before(
        <el-table-column label="类型" prop="actualType" formatter={ (_, __, value) => this.cardTypes[value] } />
      )

      return vnode
    }

  }


  return PackageCardIndexView
}
