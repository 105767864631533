import { Component, Watch } from '~/commons/component/decorators'

import { createVNodeProxy } from '@rc/vue-vdom'

import { ajax } from '~/corefeatures/net/ajax'

export
function CouponListViewProvider(SuperComponent) {
  @Component()
  class CouponListView extends SuperComponent {
    exportCouponOrder(couponId, status) {
      ajax(
        '/api/export_coupon_user',
        {
          method: 'POST',
          body: {
            coupon_id: couponId,
            status,
          }
        },
        (error, data) => {
          if (error) {
            this.$message({ message: error.message ?? '获取数据失败', type: 'error' })
          } else {
            this.download(data)
          }
        }
      )
    }

    download(url) {
      const anchor = document.createElement('a')
      anchor.download = ''
      anchor.href = url
      document.body.append(anchor)
      anchor.click()
      anchor.remove()
    }

    render() {
      const vnode = super.render()
      const root = createVNodeProxy(vnode)

      const { value: operationColumnView } = root.query({ as: <el-table-column label="操作" /> }).next()

      if (operationColumnView) {
        const renderScopedSlot = operationColumnView.scopedSlots.default

        operationColumnView.scopedSlots.default = scope => {
          const children = renderScopedSlot.call(this, scope)

          if (scope.row.type !== 9) {
            children.unshift(
              <router-link to={ { name: 'orderList', query: { id: scope.row.id } } }>
                <el-button size="small">查看订单</el-button>
              </router-link>
            )
          }

          return children
        }
      }

      const { value: columnView1 } = root.query({ as: <el-table-column prop="extend" /> }).next()

      if (columnView1) {
        columnView1.scopedSlots.default = scope => (
          <span style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <span>{ scope.row.extend }</span>
            {
              Boolean(Number(scope.row.extend)) &&
              <el-button type="primary" size="small" onClick={ () => this.exportCouponOrder(scope.row.id, 0) }>导出</el-button>
            }
          </span>
        )
      }

      const { value: columnView2 } = root.query({ as: <el-table-column prop="used" /> }).next()

      if (columnView2) {
        columnView2.scopedSlots.default = scope => (
          <span style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <span>{ scope.row.used }</span>
            {
              Boolean(Number(scope.row.used)) &&
              <el-button type="primary" size="small" onClick={ () => exportCouponOrder(scope.row.id, 99) }>导出</el-button>
            }
          </span>
        )
      }

      return vnode
    }
  }


  return CouponListView
}
